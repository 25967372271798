import { HIDE_NOTIFICATIONS, SHOW_NOTIFICATIONS } from "./actionTypes";

export const showNotifications = (message, type = "success") => {
  return {
    type: SHOW_NOTIFICATIONS,
    payload: {
      message,
      type,
    },
  };
};

export const hideNotifications = () => {
  return {
    type: HIDE_NOTIFICATIONS,
  };
};
