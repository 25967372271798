import React from "react";
import { Route, Router as BrowserRouter, Switch } from "react-router-dom";
import Public from "./route/Public";
import routes from "./route/routes";
import { createBrowserHistory } from "history";
import NotFound from "./layouts/NotFound";

function App() {
  return (
    <BrowserRouter history={createBrowserHistory()}>
      <Switch>
        {routes.map((route, index) => (
          <Public exact key={index} {...route} />
        ))}
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
