import * as types from "./actionTypes";

const initialState = {
  selected_category: {},
  selected_sub_category: {},
};

const judgingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_JUDGING_DATA:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default judgingReducer;
