import { HIDE_NOTIFICATIONS, SHOW_NOTIFICATIONS } from "./actionTypes";

const initialState = {
  open: false,
  message: "",
  type: "success",
};

const notificationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_NOTIFICATIONS:
      return {
        ...state,
        open: true,
        message: payload.message,
        type: payload.type,
      };

    case HIDE_NOTIFICATIONS:
      return {
        ...state,
        open: false,
      };

    default:
      return state;
  }
};

export default notificationReducer;
