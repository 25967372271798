import { Paper, Typography } from "@mui/material";
import React from "react";

function NotFound() {
  return (
    <Paper
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography>404 - Not Found</Typography>
    </Paper>
  );
}

export default NotFound;
