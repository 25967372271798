import { combineReducers } from "redux";
import notifications from "../layouts/notifications/store/reducer";
import auth from "../modules/auth/store/reducer";
import judging from "../modules/judging/store/reducer";
import settings from "../modules/settings/store/reducer";

const reducers = combineReducers({
  auth,
  notifications,
  judging,
  settings,
});

export default reducers;
