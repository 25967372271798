const publicRoutes = [
  {
    path: "/",
    component: "modules/home/pages/Home",
  },
  {
    path: "/judging",
    component: "modules/judging/pages/Judging",
  },
];

const routes = [...publicRoutes];

export default routes;
