import { LOG_OUT, SET_USER } from "./actionTypes";

const initialState = {
  user: {},
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER:
      return {
        ...state,
        user: payload,
      };

    case LOG_OUT:
      return {
        ...state,
        user: {},
      };

    default:
      return state;
  }
};

export default authReducer;
